var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Prop, Component } from 'vue-property-decorator';
import { copyToClipboard } from 'quasar';
import { setClickCounter } from '@/shared/utils/helpers';
import { mixpanelIncrement, mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
let TextClickToCopy = class TextClickToCopy extends Vue {
    constructor() {
        super(...arguments);
        this.clickCopyItem = false;
        this.failedCopyItem = false;
    }
    async copyTextItem() {
        this.clickCopyItem = true;
        //registrando evento no tag manager
        //@ts-ignore
        window.dataLayer.push({ event: 'texto_copiado' });
        setClickCounter();
        mixpanelTracking('Copiou dados de uma companhia');
        mixpanelIncrement('Copiou dados de uma companhia');
        //await this.getUserHasUpdatedAccount()
        copyToClipboard(this.copyItem)
            .then(r => {
            setTimeout(() => {
                this.clickCopyItem = false;
            }, 1000);
        })
            .catch(r => {
            setTimeout(() => {
                this.clickCopyItem = false;
                this.failedCopyItem = true;
            }, 1000);
        });
        await this.registerCopyEvent();
    }
    showIcon(title) {
        return [
            'Faturamento estimado anual da empresa:',
            'Faturamento estimado anual do CNPJ:',
            'Faixa estimada de funcionários da empresa:',
            'Faixa estimada de funcionários do CNPJ:',
        ].includes(title);
    }
    tooltipCopy(title) {
        if ([
            'Faturamento estimado anual da empresa:',
            'Faturamento estimado anual do CNPJ:',
        ].includes(title)) {
            return 'Dado gerado por algoritmo interno baseado no RAIS e LinkedIn';
        }
        return 'Dado proveniente do RAIS e do LinkedIn';
    }
    async registerCopyEvent() {
        // @ts-ignore
        if (this.searchState.currentCompany && this.searchState.currentCompany.id) {
            // @ts-ignore
            await this.actionsCompanies.registerCopyData({
                companyId: 
                // @ts-ignore
                this.searchState.currentCompany.id &&
                    // @ts-ignore
                    this.searchState.currentCompany.id,
                fieldCopied: this.titleItem,
            });
        }
    }
    handleCopyIcon() {
        return this.clickCopyItem
            ? 'icon-copy-success'
            : this.failedCopyItem
                ? 'icon-copy-failed'
                : 'icon-copy';
    }
};
__decorate([
    Prop({ default: '' })
], TextClickToCopy.prototype, "copyItem", void 0);
__decorate([
    Prop({ default: '' })
], TextClickToCopy.prototype, "titleItem", void 0);
TextClickToCopy = __decorate([
    Component(connect(({ state, actions }) => ({
        searchState: state.search,
        actionsCompanies: actions.companies,
        usersActions: actions.users,
        actionsSearch: actions.search,
        activeModal: state.search.activeModal,
        updateDataPopUp: state.search.updateDataPopUp,
    }), {
        name: 'TextClickToCopy',
    }))
], TextClickToCopy);
export default TextClickToCopy;
